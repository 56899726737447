.brand-logo {
  max-width: 10rem;
}

.editable {
  border: none;
  padding: 0px;
  margin: 0px;
  height: auto;
  line-height: 1;
}
h1 .editable {
  font-size: 2.5rem;
}

.table td span.label {
  display: none;
}

@media (max-width: 768px) {
  .table th {
    display: none;
  }
  .table td {
    display: block;
  }
  .table td span.label {
    display: inline-block;
    width: 50%;
  }
  .table tr td:last-child {
    margin-bottom: 1rem;
    border-bottom: solid 1px;
  }
}

.no-wrap {
  white-space: nowrap;
}
